export default [
  {
    title: 'home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'userList',
    icon: 'UserIcon',
    route: 'apps-users-list',
  },
  {
    title: 'requests',
    icon: 'UploadIcon',
    route: {
      name: 'trading-requests-list',
      params: { param: 'all' },
    },
    children: [
      {
        title: 'createNew',
        route: {
          name: 'trading-requests-add',
          params: { id: 'new' },
        },
      },
      {
        title: 'manageRequests',
        route: {
          name: 'trading-requests-list',
          params: { param: 'all' },
        },
      },
      {
        title: 'requestsList',
        route: {
          name: 'trading-requests-list',
          params: { param: 'open' },
        },
      },
    ],
  },
  {
    title: 'offers',
    icon: 'DownloadIcon',
    route: 'trading-offers-list',
    children: [
      {
        title: 'createNew',
        route: {
          name: 'trading-offers-add',
          params: { id: 'new' },
        },
      },
      {
        title: 'manageOffers',
        route: 'trading-offers-list',
      },
      {
        title: 'openOffersList',
        route: {
          name: 'trading-open-offers-list',
          params: { param: 'open' },
        },
      },
    ],
  },
  {
    title: 'deals',
    route: {
      name: 'trading-deals-list',
      params: { param: 'all' },
    },
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'warehouse',
    route: {
      name: 'trading-warehouse-list',
    },
    icon: 'LayersIcon',
  },
  {
    title: 'documents',
    route: '',
    disabled: true,
    icon: 'FileTextIcon',
  },
  {
    title: 'chat',
    route: '',
    disabled: true,
    icon: 'MessageCircleIcon',
  },
  {
    title: 'notifications',
    route: 'apps-notifications',
    icon: 'BellIcon',
  },
  {
    title: 'myAccount',
    route: 'account-settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'helpCenter',
    route: '',
    icon: 'HelpCircleIcon',
    disabled: true,
  },
];
