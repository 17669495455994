<template>
  <b-card v-if="editVerify">
    <b-row>
      <b-col
        cols="12"
        md="4"
        lg="4"
      >
        <b-form-group
          :label="`${$t('margin')}, %`"
          label-for="verify-margin"
        >
          <b-form-input
            id="verify-margin"
            v-model="margin"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex flex-column"
        cols="12"
        md="8"
        lg="8"
      >
        <div>{{ $t('finalOfferCost') }}</div>
        <div class="mt-1">
          {{ currency | currencySymbol }}{{ finalPriceWithMargin }}
        </div>
      </b-col>
      <b-col
        class="d-flex justify-content-center align-items-center"
        cols="12"
        lg="12"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="verifyOffer"
        >
          {{ $t('verify') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
  <b-card v-else>
    <b-row>
      <b-col
        class="d-flex flex-column"
        cols="12"
        md="6"
        sm="6"
        lg="3"
      >
        <div>{{ $t('margin') }}</div>
        <div class="mt-1">
          %{{ margin }}
        </div>
      </b-col>
      <b-col
        class="d-flex flex-column"
        cols="12"
        md="6"
        sm="6"
        lg="5"
      >
        <div>{{ $t('finalOfferCost') }}</div>
        <div class="mt-1">
          {{ currency | currencySymbol }}{{ finalPriceWithMargin }}
        </div>
      </b-col>
      <b-col
        class="d-flex flex-column justify-content-center align-items-end"
        cols="12"
        lg="4"
        sm="12"
        md="12"
      >
        <feather-icon
          icon="EditIcon"
          class="d-inline cursor-pointer"
          @click="editVerify = true"
        />
        <div class="d-flex align-items-center mt-1">
          <b-avatar
            size="32"
            :variant="`light-${resolveOfferStatusVariantAndIcon(verified).variant}`"
          >
            <feather-icon :icon="resolveOfferStatusVariantAndIcon(verified).icon" />
          </b-avatar>
          <span class="align-middle ml-50">
            {{ resolveOfferStatusVariantAndIcon(verified).status }}
          </span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { resolveOfferStatusVariantAndIcon } from '@/utils/offerUtils.js';
import {
  BAvatar, BButton, BCard, BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'TradingOfferVerification',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    currency: {
      type: Number,
      default: 0,
    },
    offerPrice: {
      type: [Number, String],
    },
    verified: {
      type: [Number, Boolean],
    },
    defaultMargin: {
      type: [Number, String],
    },
  },
  data() {
    return {
      editVerify: false,
      margin: null,
    };
  },
  computed: {
    finalPriceWithMargin() {
      return ((this.offerPrice * this.margin / 100) + Number(this.offerPrice)).toFixed(2);
    },
  },
  watch: {
    defaultMargin: {
      immediate: true,
      handler() {
        this.margin = this.defaultMargin;
      },
    },
    verified: {
      immediate: true,
      handler() {
        this.editVerify = !this.verified;
        if (this.verified === 1) {
          this.editVerify = !!this.verified;
        }
      },
    },
  },
  methods: {
    resolveOfferStatusVariantAndIcon,
    async verifyOffer() {
      const { data } = await this.$http.post(`/offers/verify/${this.$route.params.id}`, { margin: this.margin });

      const { margin, verified } = data.data.offer;
      this.editVerify = false;
      this.$emit('update:verified', verified);
      this.$emit('update:default-margin', margin);
      this.$emit('change-price', this.finalPriceWithMargin);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'The offer verified successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });

      await this.$store.dispatch('offers/fetchOfferById', { offerId: this.$route.params.id });
    },
  },
};
</script>
