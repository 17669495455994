<template>
  <div>
    <section class="offer-add-wrapper">
      <b-row
        class="offer-add"
      >
        <!-- Col: Left (Offer Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <trading-new-country
            :header="!isNewMode ? $t('editOffer', { offerId: offerIdProp || offer.id}) : $t('newOffer')"
            :is-offer="true"
            :offer-id="offerId"
            :currency.sync="offer.currency"
            :lead.sync="offer.lead_time"
            :country-id.sync="countryId"
          >
            <template #status>
              <b-badge
                :variant="status.variant"
                class="px-1"
              >
                {{ status.name }}
              </b-badge>
            </template>
          </trading-new-country>
          <trading-offers-new-item
            :products="offer.products || []"
            :title="$t('products')"
            :currency="offer.currency"
            :margin="offer.margin"
            :is-product-from-item="!!itemsForOfferProp.length"
            :created-products-ids="createdProductsIds"
            :on-request="!!offer.request"
            @update-products="updateProducts"
          />
          <trading-offer-transactions
            v-if="isUserAdmin && !!offer.request"
            :title="$t('documentsAndPayments')"
            :offer="offer"
          />
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
        >
          <trading-user-select-by-manager
            v-if="isAdmin"
            :title="$t('supplier')"
            :header-label="$t('selectSupplier')"
            :options="optionsForSelectSupplier"
            :user.sync="offer.legal_id"
          />
          <trading-new-validity-period
            :title="$t('validityPeriodOfTheOffer')"
            :date="offer.valid_until"
            @update="updateValidityTerm"
          />
          <trading-new-save-aside
            item-type="offer"
            :is-new-mode="isNewMode"
            :item-id="currentOffer.id"
            :full-cost="offer.price"
            :currency="offer.currency"
            :message.sync="offer.note"
            :is-save-disabled="offer.products.length < 1 || !offer.lead_time && !offer.request || (!isNewMode && !offer.can_edit && !offer.request)"
            :is-item-editable="isNewMode || (!isNewMode && !offer.can_edit)"
            :is-item-removable="currentOffer.can_delete"
            :is-ok-button-spinning="isOkButtonSpinning"
            @save="handleSave"
            @publish="handlePublish"
            @update="setNote"
            @cancel="cancel"
            @delete="deleteOffer"
          />
          <TradingOfferVerification
            v-if="isUserAdmin && !offer.request"
            :currency="offer.currency"
            :offer-price="offer.real_price"
            :verified.sync="offer.status"
            :default-margin="offer.margin"
          />
        </b-col>
      </b-row>
    </section>

    <confirm-modal
      :modal-visible="saveConfirmVisible"
      :modal-message="String($t('saveWithUnsavedConfirmMessage'))"
      :ok-text="String($t('yesSave'))"
      :cancel-text="String($t('noCancel'))"
      @modal-hidden="saveConfirmVisible = false"
      @yes-clicked="save"
      @no-clicked="saveConfirmVisible = false"
    />

    <confirm-modal
      :modal-visible="publishConfirmVisible"
      :modal-message="String($t('saveWithUnsavedConfirmMessage'))"
      :ok-text="String($t('yesSave'))"
      :cancel-text="String($t('noCancel'))"
      @modal-hidden="publishConfirmVisible = false"
      @yes-clicked="publish"
      @no-clicked="publishConfirmVisible = false"
    />
  </div>
</template>

<script>
import { BRow, BCol, BBadge } from 'bootstrap-vue';
import TradingNewCountry from '@/views/trading/TradingNewCountry.vue';
import TradingNewValidityPeriod from '@/views/trading/TradingNewValidityPeriod.vue';
import TradingNewSaveAside from '@/views/trading/TradingNewSaveAside.vue';
import TradingOffersNewItem from '@/views/trading/trading-add-item/offers/TradingOffersNewItem.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';
import TradingOfferVerification from '@/views/trading/trading-offers/TradingOfferVerification.vue';
import TradingUserSelectByManager from '@/views/trading/TradingUserSelectByManager.vue';
import TradingOfferTransactions from '@/views/trading/trading-offers/transactions/TradingOfferTransactions.vue';
import { offerStatuses } from '@/constants/offers.js';
import axios from '@/libs/axios';
import router from '@/router';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';

export default {
  components: {
    confirmModal,
    TradingOfferVerification,
    BRow,
    BBadge,
    BCol,
    TradingNewCountry,
    TradingUserSelectByManager,
    TradingNewValidityPeriod,
    TradingNewSaveAside,
    TradingOffersNewItem,
    TradingOfferTransactions,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    useMode: {
      type: String,
      default: 'createOrEdit', // can be 'createAndAddProducts', 'editAndAddProducts'
    },
    isNewModeProp: {
      type: Boolean,
      default: false,
    },
    offerIdProp: {
      type: Number,
      default: null,
    },
    itemsForOfferProp: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    offer: {
      id: null,
      created_by: null,
      owned_by: null,
      price: '0.00',
      currency: 1,
      packing: null,
      valid_until: '',
      note: '',
      status: 0,
      legal_id: '',
      lead_time: null,
      last_comment: null,
      created_at: null,
      updated_at: null,
      products: [],
      can_delete: null,
    },
    countryId: '',
    offerId: '',
    isOkButtonSpinning: false,
    createdProductsIds: [],
    saveConfirmVisible: false,
    publishConfirmVisible: false,
  }),
  computed: {
    isNewMode() {
      return this.isNewModeProp || this.$route.params.id === 'new';
    },
    status() {
      return offerStatuses[this.offer.status || 0];
    },
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    isUserAdmin() {
      return this.$store.getters['profile/isAdmin'] && (!this.isNewModeProp || this.$route.params.id !== 'new');
    },
    currentOffer() {
      return this.$store.state.offers.currentOffer;
    },
    dataToSend() {
      const sendData = {
        currency: this.offer.currency,
        lead_time: this.offer.lead_time,
        note: this.offer.note,
        product_ids: this.offer.products.filter(item => !item.isNeedSave).map(item => item.id),
        country_id: this.countryId,
        valid_until: this.offer.valid_until,
      };
      if (this.$store.getters['profile/isAdmin'] && this.offer.legal_id) {
        sendData.legal_id = this.offer.legal_id;
      }
      return sendData;
    },
    isAllowToSelectSupplier() {
      return this.$store.getters['profile/isAdmin'] && this.currentOffer.can_edit;
    },
    optionsForSelectSupplier() {
      const options = [];

      if (this.isAllowToSelectSupplier && this.$store.state.profile?.profile?.company?.id) {
        options.push({
          id: this.$store.state.profile.profile.company.id, // need change to profile.id (and in another places)
          company_name: `${this.$store.state.profile.profile.company.company_name} (Admin)`,
        });
      }

      return [...options, ...this.$store.state.offers.legalSuppliers];
    },
    isSupplierOnly() {
      return this.$store.getters['profile/isSupplierOnly'];
    },
  },
  watch: {
    currentOffer: {
      handler(val) {
        this.offer = {
          ...this.offer,
          ...val,
          country_id: this.countryId,
        };
      },
      deep: true,
    },
    isAdmin(value) {
      if (value) this.$store.dispatch('offers/fetchLegalSuppliers');
    },
  },
  async created() {
    await this.initOffer();
    if (this.$store.getters['profile/isAdmin']) {
      await this.$store.dispatch('offers/fetchLegalSuppliers');
    }
  },
  methods: {
    async initOffer() {
      try {
        if ((!this.isNewModeProp && this.offerIdProp) || (this.$route.params.id && this.$route.params.id !== 'new')) {
          await this.$store.dispatch('offers/fetchLegalOptions');
          await this.$store.dispatch('offers/fetchOfferById', {
            offerId: this.offerIdProp || this.$route.params.id,
            isAdmin: this.isAdmin,
          });
          const { currentOffer } = this.$store.state.offers;
          this.offer = {
            ...this.offer,
            ...this.$store.state.offers.currentOffer,
            country_id: currentOffer?.country?.id,
            legal_id: currentOffer?.owned_by?.company_id || '',
          };

          if (this.itemsForOfferProp.length) {
            const copyProducts = JSON.parse(JSON.stringify(this.offer.products));

            const arr = [...copyProducts];

            this.itemsForOfferProp.forEach(itemForOfferProp => {
              arr.push({
                isNeedSave: true,
                analog_index: null,
                analog_name: null,
                category: null,
                vat: null,
                price: 0,
                image_ids: [],
                document_ids: [],
                packing_qty: null,
                packing_net: null,
                packing_gross: null,
                packing_meas: null,
                delivery_type: 'retail',
                country: 0,
                total_sum: 0,
                warranty: 0,
                tnved_codes: null,
                available: true,
                images: [],
                documents: [],
                ...itemForOfferProp,
              });
            });

            this.updateProducts({ data: arr });
          }
          this.countryId = this.offer.country_id;
          this.offerId = `#${this.offer.id}`;
        }
        if (this.isNewModeProp || (this.$route.params.id && this.isNewMode)) {
          this.offerId = this.$t('new');

          if (this.itemsForOfferProp.length) {
            const arr = [];

            this.itemsForOfferProp.forEach(itemForOfferProp => {
              arr.push({
                isNeedSave: true,
                analog_index: null,
                analog_name: null,
                category: null,
                vat: null,
                price: 0,
                image_ids: [],
                document_ids: [],
                packing_qty: null,
                packing_net: null,
                packing_gross: null,
                packing_meas: null,
                delivery_type: 'retail',
                country: 0,
                total_sum: 0,
                warranty: 0,
                tnved_codes: null,
                available: true,
                images: [],
                documents: [],
                ...itemForOfferProp,
              });
            });

            this.$nextTick(() => {
              this.updateProducts({ data: arr });
            });
          }
        }
      } catch {
        this.$router.go(-1);
      }
    },
    setNote(data) {
      this.offer.note = data;
    },
    updateValidityTerm(date) {
      this.offer.valid_until = date;
    },
    updateProducts(data) {
      if (data.needPush) {
        this.createdProductsIds.push(data.data[data.data.length - 1].id);
      }

      this.offer = {
        ...this.offer,
        products: data.data,
      };
    },
    async save() {
      if (!this.offer.products.length) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('addProductsError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      if (this.isNewMode && this.offer.products.length) {
        await this.createOffer();
      }
      if (!this.isNewModeProp && !this.isNewMode) {
        await this.updateOffer();
      }

      this.saveConfirmVisible = false;
    },
    async updateOffer() {
      const { data } = await this.$http.post(`/offers/update/${this.offerIdProp || this.$route.params.id}`, this.dataToSend);
      if (data.status) {
        this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
        this.offer = {
          ...this.offer,
          ...data.data.offer,
          country_id: this.countryId,
        };
      }
    },
    async createOffer() {
      const { data } = await this.$http.post('/offers', this.dataToSend);
      if (data.status) {
        if (this.isNewModeProp) {
          this.$emit('close-offers-new');
        } else {
          await this.$router.push({ name: this.$router.currentRoute.name, params: { id: data.data.offer.id } });
        }

        this.offerId = `#${data.data.offer.id}`;
        this.offer = {
          ...this.offer,
          ...data.data.offer,
          country_id: this.countryId,
        };
        this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
      } else {
        this.setSystemNotification(data.error, 'AlertTriangleIcon', 'danger');
      }
    },
    setSystemNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      });
    },
    async publish() {
      await this.save();
      const availableProducts = this.offer.products.filter(product => product.available);
      if (availableProducts.some(product => !product.price)) {
        this.setSystemNotification('Please complete all items of the request', 'AlertTriangleIcon', 'danger');
        return;
      }
      const { data } = await this.$http.post(`/offers/publish/${this.offerIdProp || this.$route.params.id}`, this.dataToSend);
      if (data.status) {
        this.offer = {
          ...this.offer,
          ...data.data.offer,
          country_id: this.countryId,
        };
        this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
      }

      this.publishConfirmVisible = false;
    },
    cancel() {
      this.$router.go(-1);
    },
    async deleteOffer() {
      this.isOkButtonSpinning = true;

      await axios.delete(`/v1/offers/${this.currentOffer.id}/delete`).then(async () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('offerDeletedSuccessfully', { id: this.currentOffer.id }),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });

        if (this.isAdmin) {
          if (this.isNewModeProp || this.offerIdProp) {
            this.$emit('close-offers-new');
          } else {
            await router.push({ name: 'trading-offers-list' });
          }
        }

        if (this.isSupplierOnly) {
          if (this.isNewModeProp || this.offerIdProp) {
            this.$emit('close-offers-new');
          } else {
            await router.push({ name: 'trading-open-offers-list', params: { param: 'own' } });
          }
        }

        this.modalVisible = false;
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong', { msg: e.response.data.message }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }).finally(() => {
        this.isOkButtonSpinning = false;
      });
    },
    handleSave() {
      if (this.offer.products.filter(product => product.isNeedSave).length) {
        this.saveConfirmVisible = true;
      } else {
        this.save();
      }
    },
    handlePublish() {
      if (this.offer.products.filter(product => product.isNeedSave).length) {
        this.publishConfirmVisible = true;
      } else {
        this.publish();
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
